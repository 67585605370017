<template>
    <v-card elevation="0" width="500">
        <v-card-text id="voucher-card">
            <section class="description mb-10">
                <p>
                    <span class="description_id">
                        {{ orderDescription }}
                    </span>
                <h2 class="description_charge">
                    {{ `${formatNumber(charge)} MXN` }}
                </h2>
                </p>
            </section>
            <section v-if="(bankInformation.clabe)">
                <p class="d-flex flex-column">
                    <span class="mb-2">
                        Información bancaria:
                    </span>
                    <span class="bankinfo_name">
                        {{ `Beneficiario: ${beneficiary} a través de WeChamber` }}
                    </span>
                </p>
            </section>
            <section class="mb-4" v-if="(bankInformation.clabe)">
                <v-sheet>
                    <v-simple-table dense class="bank-table">
                        <tbody>
                            <tr v-for="item in formatBankInstructions()" :key="item.id">
                                <td>{{ item.id }}</td>
                                <td>{{ item.value }}</td>
                                <td>
                                    <v-btn x-small icon v-clipboard:copy="">
                                        <v-icon> mdi-clipboard </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-sheet>
            </section>
            <section class="terms">
                <p v-if="(bankInformation.clabe)">
                    Si es posible, incluye la referencia mencionada anteriormente cuando realices la transferencia
                    bancaria
                </p>
                <p v-else class="mb-7" style="font-size: 1.1rem; text-align: center;">
                    Esta order ya ha sido pagada.
                </p>
                <p class="terms-powered">
                    Powered by WeChamber
                </p>
            </section>
        </v-card-text>
    </v-card>
</template>
<script>
import { formatPrice } from "@/utils";

export default {
    props: {
        instructions: {
            type: Object,
            default: (() => {})
        },
        description: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            charge: this.instructions.amount_remaining ? parseInt(this.instructions.amount_remaining) / 100 : 0,
            bankInformation: this.instructions.financial_addresses ? this.instructions.financial_addresses[0].spei : {},
            orderDescription: this.description || '',
            beneficiary: 'AMPI',
            instructionsFormat: {
                "reference": "Referencia",
                "bank_name": "Nombre del banco",
                "clabe": "CLABE",
                "receptor": "Beneficiario"
            }
        }
    },
    methods: {
        formatNumber(total) {
            return formatPrice(total)
        },
        formatBankInstructions() {
            let newFormat = [];
            console.log(this.bankInformation);
            delete this.bankInformation.bank_code
            this.bankInformation.reference = this.instructions.reference
            this.bankInformation.receptor = "WeChamber"
            Object.keys(this.instructionsFormat).forEach((element) => {
                newFormat.push({ id: this.instructionsFormat[element], value: this.bankInformation[element] })
            })
            return newFormat
        },
    },
}
</script>
<style scoped lang="scss">
#voucher-card {
    padding: 32px;
}

.description {
    &_charge {
        font-size: 2rem;
        color: black;
    }
}

.bankinfo_name {
    text-align: center;
    font-weight: 600;
    color: black;
}

.bank-table {
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    background-color: #f7f7f7;
}

.terms {
    font-weight: 500;

    &-powered {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: rgb(102, 102, 102);
    }
}
</style>